














































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    event: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        type: "",
        prefix: "",
        startDate: "",
        endDate: "",
        amount: "",
        languageVersion: "",
        invoice: "",
        company: "",
        abstract: "",
        posterSession: "",
        survey: "",
        print: "",
        location: "",
      }),
    },
  },

  setup(props, { root }) {
    const model = reactive<{
      location: string;
      data: any;
    }>({
      location: props.event.location.id,
      data: {},
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      dialog: false,
      locations: [],
    });

    //  Start fetch locations

    const fetchLocations = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("location")
        .then(({ data: { locations } }) => {
          state.locations = locations;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.locations = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchLocations);

    //  End fetch locations

    //  Start fetch locations

    const fetchSingle = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`location/${model.location}`)
        .then(({ data: { location } }) => {
          model.location = location.id;
          model.data = location;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.locations = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSingle);

    //  End fetch locations

    watch(() => model.location, fetchSingle);

    // Start add location to event

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .post(
          `event/${root.$route.params.id}/location/${model.location}`,
          {},
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
          fetchSingle();
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    // End add location to event

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.objectExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/location/`
    );

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      filesUrl,
    };
  },
});
